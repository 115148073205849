/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("title", null, "Back To Life | Michael Weslander"), "\n", React.createElement(_components.h1, null, "Title: Back To Life"), "\n", React.createElement(_components.h2, null, "Artist: Michael Weslander"), "\n", React.createElement(_components.h3, null, "Comfort: 2"), "\n", React.createElement(_components.h4, null, "Key: Am"), "\n", React.createElement(_components.p, null, "Been around the world on a one way ticket\nNow I'm headed on a plane to Spain"), "\n", React.createElement(_components.p, null, "The thrill of the chase has my heart racing\nWith the thought of a new place"), "\n", React.createElement(_components.p, null, "I've been driving just a little too fast\nOn the wrong side of the road"), "\n", React.createElement(_components.p, null, "My friends have been calling\nBut I've been falling\nIn love with growing old"), "\n", React.createElement(_components.p, null, "I could meet you there\nIf I could show me how to care"), "\n", React.createElement(_components.p, null, "Everyone I know said I\nWould fall in love again\nI couldn't believe a word they said\nUntil you came\nAnd brought me back to Life"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
